import { Button, Card, CardContent, Input, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ANAO, ASIM } from 'consts';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PerfectScrollbar from 'react-perfect-scrollbar';
import api from '../../../services/api';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import AlertInformation from 'components/AlertInformation/AlertInformation';

class ModeloVeiculoList extends Component {
    state = {
        modelosveiculos: [],
        searchFilter: {
            nomeModeloVeiculo: '',
            situacaoInativoFilter: ANAO,
        },
    };

    estilo = {
        marginLeft: 30,
        marginTop: 30,
        marginRight: 30,
        container: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        addButton: {
            height: '42px',
            display: 'flex',
            alignItems: 'center',
            float: 'right', // alinhamento a direita
            marginTop: 3,
            marginRight: 3
        },
        redText: {
            color: 'red',
        },
    };

    constructor(props) {
        super(props);
    }

    /* carregamento inicial */
    async componentDidMount() {
        this.loadModelosVeiculo();
    }

    /* Carrega modelos veículo */
    loadModelosVeiculo = async () => {
        const { searchFilter } = this.state;
        let params = '';

       params += '?sort=tbmodeloveiculo.codmodeloveiculo'

        const response = await api.get('/modeloveiculo' + params);
        this.setState({ modelosveiculos: response.data });
    }

    /* Escolha de um registro para exclusão */
    handleSelectItem = (id) => {
        this.setState({ selectedId: id });
    };

    /* deleta modelo veículo */
    handleDelete = async (id) => {
        try {
            const response = await api.delete(`/modeloveiculo/${id}`);
            // Verifica se a exclusão foi bem-sucedida
            if (response.status === 200) {
                this.handleClose();
                this.loadModelosVeiculo();
            }
        } catch (error) {
            this.handleClose();
            this.handleOpenAlert(error.response.data.error);
            console.log(error);
            console.log(error.message);
        }
    };

    /* abre janela confirmação para exclusão */
    handleOpen = (codmodeloveiculo) => {
        this.setState({ open: true });
        this.handleSelectItem(codmodeloveiculo);
    };

    /* abre janela de alerta com mensagem */
    handleOpenAlert = (message) => {
        this.setState({ openAlert: true });
        this.setState({ messageAlert: message });
    };

    /* fecha janela de confirmação exclusão */
    handleClose = () => {
        this.setState({ open: false });
    };

    /* fecha janela mensagem alerta */
    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    }

    /* Pesquisar filtros na tela */
    OnChangeSearch = (event) => {
        const { searchFilter } = this.state;
        let { name, value } = event.target;
        value = value.toUpperCase()
        searchFilter[name] = value;
        this.setState({ searchFilter: searchFilter });
        if (name === 'situacaoInativoFilter') {
            this.loadModelosVeiculo();
        }
    };

    /* Limpa Filtros na tela */
    LimpaFiltros = () => {
        let { searchFilter } = this.state;
        searchFilter.nomeModeloVeiculo = '';
        searchFilter.situacaoInativoFilter = 'N';
        this.setState({ searchFilter: searchFilter });
    }

    render() {
        const { modelosveiculos } = this.state;
        const { searchFilter } = this.state;
        return (
            <div style={this.estilo}>
                <Button
                    component={Link}
                    to="/frotas/modeloveiculo/novo"
                    variant="contained"
                    color="primary" 
                    style={this.estilo.addButton}
                >
                    Novo
                </Button>

                <Paper hover>
                    <Input
                        autoFocus
                        id="nomeModeloVeiculo"
                        name="nomeModeloVeiculo"
                        onBlur={this.onInputBlur}
                        onChange={this.OnChangeSearch}
                        placeholder="Nome modelo veículo a pesquisar"
                        style={{ marginBottom: 10, width: 300, marginLeft: 10, marginTop: 10 }}
                        value={searchFilter.nomeModeloVeiculo}
                    />

                    <Select
                        id="situacaoInativoFilter"
                        name="situacaoInativoFilter"
                        style={{ marginLeft: 20 }}
                        value={searchFilter.situacaoInativoFilter || ''}
                        onChange={this.OnChangeSearch}
                    >
                        <MenuItem value={ANAO}>Ativos</MenuItem>
                        <MenuItem value={ASIM}>Inativos</MenuItem>
                        <MenuItem value={'T'}>Todos</MenuItem>
                    </Select>

                    <Button
                        color="primary"
                        id="pesquisar"
                        onClick={() => this.loadModelosVeiculo()}
                    >
                        <SearchIcon />
                    </Button>

                    <Button
                        color="primary"
                        onClick={() => this.LimpaFiltros()}
                    >
                        <ClearAllIcon />
                    </Button>
                </Paper>

                <Card>
                    <CardContent>
                        <PerfectScrollbar>
                            <div >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Descrição</TableCell>
                                            <TableCell>Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {modelosveiculos.slice(0, 100).map(modeloveiculo => (
                                            <TableRow
                                                hover
                                                key={modeloveiculo.codmodeloveiculo}
                                            >
                                                <TableCell style={(modeloveiculo.inativo === ASIM) ? this.estilo.redText : {}}>{modeloveiculo.codmodeloveiculo}</TableCell>

                                                <TableCell style={(modeloveiculo.inativo === ASIM) ? this.estilo.redText : {}} >{modeloveiculo.descmodeloveiculo}</TableCell>

                                                <TableCell>
                                                    <Link to={`/frotas/modeloveiculo/${modeloveiculo.codmodeloveiculo}`}>
                                                        <EditOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                                                    </Link>
                                                    <Link to={`/frotas/modeloveiculo/${modeloveiculo.codmodeloveiculo}/view`}>
                                                        <PageviewOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                                                    </Link>
                                                    <DeleteForeverOutlinedIcon
                                                        onClick={() => this.handleOpen(modeloveiculo.codmodeloveiculo)}
                                                        style={{ cursor: 'pointer', marginRight: '10px' }} />
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </PerfectScrollbar>
                    </CardContent>
                </Card>

                <DeleteConfirmationModal
                    open={this.state.open}
                    handleClose={() => this.handleClose()}
                    itemId={this.state.selectedId}
                    message={"Confirma exclusão do modelo de veículo " + this.state.selectedId + '?'}
                    handleDelete={() => this.handleDelete(this.state.selectedId)}
                />

                <AlertInformation
                    open={this.state.openAlert}
                    handleClose={() => this.handleCloseAlert()}
                    message={this.state.messageAlert}
                />
            </div>
        );
    }
}

export default ModeloVeiculoList;