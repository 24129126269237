import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import SettingsIcon from '@material-ui/icons/Settings';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  deeplyNested: {
    paddingLeft: theme.spacing(6),
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openVeiculos, setOpenVeiculos] = useState(false);

  const handleAdminClick = () => {
    setOpenAdmin(!openAdmin);
  };

  const handleVeiculosClick = () => {
    setOpenVeiculos(!openVeiculos);
  };

  return (
    <List>
      {/* Menu Cadastro */}
      <ListItem button onClick={handleAdminClick} className={classes.listItem}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Cadastro" />
        {openAdmin ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openAdmin} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {/* Submenu Veículos */}
          <ListItem button onClick={handleVeiculosClick} className={classes.nested}>
            <ListItemIcon>
              <SupervisorAccount />
            </ListItemIcon>
            <ListItemText primary="Veículos" />
            {openVeiculos ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={openVeiculos} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/veiculos" className={classes.deeplyNested}>
                <ListItemIcon>
                  <CreateNewFolderIcon />
                </ListItemIcon>
                <ListItemText primary="Veículo" />
              </ListItem>
              <ListItem button component={Link} to="/frotas/marcaveiculo" className={classes.deeplyNested}>
                <ListItemIcon>
                  <CreateNewFolderIcon />
                </ListItemIcon>
                <ListItemText primary="Marcas" />
              </ListItem>
              <ListItem button component={Link} to="/frotas/modeloveiculo" className={classes.deeplyNested}>
                <ListItemIcon>
                  <CreateNewFolderIcon />
                </ListItemIcon>
                <ListItemText primary="Modelos" />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Collapse>

      {/* Menu Sair */}
      <ListItem button component={Link} to="/logout" className={classes.listItem}>
        <ListItemIcon>
          <LockOpenIcon />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </ListItem>
    </List>
  );
};

export default Sidebar;
