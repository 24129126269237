import {
    Button, CardContent, FormControl, FormControlLabel, FormGroup, Switch, TextField,
    FormLabel, RadioGroup, Radio, FormHelperText, Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import api from '../../../services/api';
import { ANAO, ASIM } from 'consts';
import PropTypes from 'prop-types';
import AlertInformation from 'components/AlertInformation/AlertInformation';
import ComboPadrao from 'views/Components/ComboPadrao';

class ModeloVeiculoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modeloveiculo: {},
            marcasveiculos: [],
            isNewRecord: false,
            isView: false,
            schema: {
                usuario: {
                    presence: { allowEmpty: false, message: 'é obrigatório' },
                    length: {
                        maximum: 64
                    }
                },
            },

            useStyles: makeStyles(theme => ({
                root: {
                    backgroundColor: theme.palette.background.default,
                    height: '100%'
                },
                grid: {
                    height: '100%'
                },
                quoteContainer: {
                    [theme.breakpoints.down('md')]: {
                        display: 'none'
                    }
                },
                quote: {
                    backgroundColor: theme.palette.neutral,
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: 'url(/images/auth.jpg)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                },
                quoteInner: {
                    textAlign: 'center',
                    flexBasis: '600px'
                },
                quoteText: {
                    color: theme.palette.white,
                    fontWeight: 300
                },
                name: {
                    marginTop: theme.spacing(3),
                    color: theme.palette.white
                },
                bio: {
                    color: theme.palette.white
                },
                contentContainer: {},
                content: {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                },
                contentHeader: {
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: theme.spacing(5),
                    paddingBototm: theme.spacing(2),
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2)
                },
                logoImage: {
                    marginLeft: theme.spacing(4)
                },
                contentBody: {
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    [theme.breakpoints.down('md')]: {
                        justifyContent: 'center'
                    }
                },
                form: {
                    paddingLeft: 100,
                    paddingRight: 100,
                    paddingBottom: 125,
                    flexBasis: 700,
                    [theme.breakpoints.down('sm')]: {
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2)
                    }
                },
                title: {
                    marginTop: theme.spacing(3)
                },
                socialButtons: {
                    marginTop: theme.spacing(3)
                },
                socialIcon: {
                    marginRight: theme.spacing(1)
                },
                sugestion: {
                    marginTop: theme.spacing(2)
                },
                textField: {
                    marginTop: theme.spacing(2)
                },
                signInButton: {
                    margin: theme.spacing(2, 0)
                },
                alert: {
                    color: '#FF0000',
                }
            })),
        };
    }

    async componentDidMount() {
        // carrega informações formulário
        const { id } = this.props.match.params;
        const { location } = this.props;
        const currentPath = location.pathname;

        if (id >= 0) {
            const response = await api.get(`/modeloveiculo/${id}`);
            this.setState({
                modeloveiculo: response.data[0],
                isNewRecord: false,
                isView: (currentPath.includes('/view'))
            }
            );
            const marcasveiculos = await api.get(`/marcasveiculos/listforchange/${response.data[0].codmarcaveiculo}`);
            this.setState({ marcasveiculos: marcasveiculos.data.data });

        }

        else if (id === 'novo') {
            this.setState({
                isNewRecord: true
            });
            this.state.isNewRecord = true;
            const { modeloveiculo } = this.state;

            const marcasveiculos = await api.get(`/marcasveiculos/active/true`);
            this.setState({ marcasveiculos: marcasveiculos.data.data });
        }
    }

    /* evento on change componentes */
    OnChange = (event) => {
        const { modeloveiculo } = this.state;
        const input = event.target;
        const cursorPosition = input.selectionStart;
        const { name, type, value, checked } = event.target;

        let adjustedValue = value; // Valor ajustado com base no tipo do input      

        // Decide o tratamento com base no tipo do input ou nome
        switch (type) {
            case 'radio':
                // Converte a string numérica para número antes de salvar
                adjustedValue = Number(value);
                break;
            case 'checkbox':
                // Usa o valor checked para campos do tipo checkbox
                // Tratamentos adicionais específicos para campos
                if (name === 'inativo') {
                    if (checked === false) {
                        adjustedValue = ASIM;
                    }
                    else {
                        adjustedValue = ANAO;
                    }
                }

                if (name === 'solicitadopelocliente') {
                    if (checked === false) {
                        adjustedValue = ANAO;
                    } else {
                        adjustedValue = ASIM;
                    }
                }

                break;
            default:
                // Converte valor para maiúsculas
                adjustedValue = value.toUpperCase();
                break;
        }

        // Atualiza o estado com o valor ajustado
        modeloveiculo[name] = adjustedValue;
        this.setState({ modeloveiculo: modeloveiculo }, () => {
            // Depois que o estado é atualizado e o componente é renderizado novamente, você reposiciona o cursor
            if (input.type === "text") { // Confirma que é um campo de texto para evitar erros com outros tipos de input
                input.setSelectionRange(cursorPosition, cursorPosition);
            }
        });
    };

    /* atualiza dados de modelo de veículo existente - post */
    atualizaModeloVeiculo = async () => {
        const { modeloveiculo } = this.state;
        console.log(modeloveiculo);
        try {
            const response = await api.put(`/modeloveiculo/${modeloveiculo.codmodeloveiculo}`, modeloveiculo);
            if (response.status === 200) {
                this.props.history.push('/frotas/modeloveiculo');
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    /* abre janela de alerta com mensagem */
    handleOpenAlert = (message) => {
        this.setState({ openAlert: true });
        this.setState({ messageAlert: message });
    };

    /* fecha janela mensagem alerta */
    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    }

    /* Click salvar */
    onClickSave = () => {
        if (this.validationRequiredFields()) {
            if (this.state.isNewRecord) {
                this.insereModeloVeiculo()
            }
            else {
                this.atualizaModeloVeiculo();
            }
        }
    }

    /* Click cancelar */
    onClickCancel = () => {
        this.props.history.push('/frotas/modeloveiculo');
    }

    /* valida campos obrigatórios ao salvar */
    validationRequiredFields() {
        const { modeloveiculo } = this.state;
        let retorno = true;

        if ((String(modeloveiculo.descmodeloveiculo).trim() === '') || (modeloveiculo.descmodeloveiculo === undefined)) {
            modeloveiculo.descmodeloveiculo = '';
            this.setState({ modeloveiculo: modeloveiculo });
            retorno = false;
        };

        return retorno;
    }

    /* insere novo modelo de veículo */
    insereModeloVeiculo = async () => {
        const { modeloveiculo } = this.state;
        modeloveiculo.codusuarioregistrou = parseInt(sessionStorage.getItem('codUser'));
        try {
            const response = await api.post(`/modeloveiculo`, modeloveiculo);
            if (response.status === 200) {
                this.props.history.push('/frotas/modeloveiculo');
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    render() {
        const { modeloveiculo } = this.state;
        const { marcasveiculos } = this.state;
        return (
            <div>
                <CardContent>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Modelo de veículo {modeloveiculo.codmodeloveiculo}
                    </Typography>

                    <br />

                    <FormGroup>
                        <TextField
                            className={this.state.useStyles.textField}
                            label="Modelo de veículo"
                            style={{ marginTop: '10px' }}
                            name="descmodeloveiculo"
                            value={modeloveiculo.descmodeloveiculo || ''}
                            variant="outlined"
                            onChange={this.OnChange}
                            helperText={modeloveiculo.descmodeloveiculo === '' ? 'Modelo de veículo obrigatório' : ' '}
                            disabled={this.state.isView}
                            inputProps={{ maxLength: 50 }}
                        />
                    </FormGroup>

                    <FormControl
                        fullWidth
                        variant="outlined"
                        disabled={this.state.isView}
                    >
                        <ComboPadrao
                            name={'codmarcaveiculo'}
                            label={'Marca de veículo'}
                            value={modeloveiculo.codmarcaveiculo}
                            onChange={this.OnChange}
                            fieldId={'codmarcaveiculo'}
                            listItems={marcasveiculos}
                            fieldDescription={'descmarcaveiculo'}
                        />
                        <FormHelperText>{modeloveiculo.codmarcaveiculo === '0' ? 'Marca veículo obrigatória' : ' '}</FormHelperText>
                    </FormControl>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={!(modeloveiculo.inativo === ASIM)}
                                name="inativo"
                                onChange={this.OnChange}
                                color="primary"
                                disabled={this.state.isView}
                            />
                        }
                        label={(modeloveiculo.inativo === ASIM) ? 'Inativo' : 'Ativo'}
                    />

                    <br />
                    <br />
                    <br />

                    <Button
                        name="salvar"
                        color="primary"
                        variant="contained"
                        onClick={this.onClickSave}
                        style={{ marginTop: '10px' }}
                        disabled={this.state.isView}
                    >
                        Salvar
                    </Button>

                    <Button
                        name="cancelar"
                        variant="contained"
                        onClick={this.onClickCancel}
                        style={{ marginTop: '10px', marginLeft: '10px' }}
                        color="secondary"
                    >
                        Cancelar
                    </Button>
                </CardContent>

                <AlertInformation
                    open={this.state.openAlert}
                    handleClose={() => this.handleCloseAlert()}
                    message={this.state.messageAlert}
                />
            </div>
        );
    }
}

ModeloVeiculoForm.propTypes =
{
    classes: PropTypes.object.isRequired
};

export default ModeloVeiculoForm;